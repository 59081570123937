import React from "react";

import Layout from "../../layouts/Layout";
import VerticalBanner from "../../partials/VerticalBanner";
import VerticalCategoriesSection from "../../partials/VerticalCategoriesSection";
import VerticalCaseStudySection from "../../partials/VerticalCaseStudySection";
import VerticalInfoBanner from "../../partials/VerticalInfoBanner";
import PlatformSection from "../../partials/PlatformSection";
import VerticalPartnersSection from "../../partials/VerticalPartnersSection";
import VerticalNetworkSection from "../../partials/VerticalNetworkSection";

import { useMediaQuery } from "../../utils/hooks";

const HealthWellnessPage = props => {
  const {
    pageContext: { caseStudy },
  } = props;

  const isMobile = useMediaQuery("(max-width: 575px)");

  return (
    <Layout
      pageTitle="Brandcrush for gyms, studios & salons"
      ogTitle="Brandcrush for Health & Wellness | Monetize brand experiences your members will love"
      ogImage="/images/health@3x.png"
      ogDescription="Power media sales for your gym, club or studio with an end-to-end software solution. Make it easy for brands to find and book experiential activations and digital sponsorships with your business."
    >
      <VerticalBanner
        vertical="Health & wellness"
        heading="Monetize brand experiences your members will love"
        subHeading="Unlock new revenue by partnering with great brands for on-premise and and online activations, all powered by one simple platform."
        backgroundImgSrc="/images/cloud-vertical-light-pink.svg"
        imageSrc="/images/health@3x.png"
      />
      <VerticalCategoriesSection
        heading="Power media sales for your gym, club or studio with an end-to-end software solution"
        subHeading="Make it easy for brands to find and book experiential activations and digital sponsorships with your business."
        categories={[
          {
            label: "On-Premise",
            iconSrc: "/images/onsite.svg",
            activationTypes: [
              "Pop-ups",
              "Product sampling",
              "Studio takeovers",
              "Product placement",
              "Posters & decals",
              "On the Trainer brand experiences",
            ],
            color: "light-blue",
          },
          {
            label: "Online",
            iconSrc: "/images/online.svg",
            activationTypes: [
              "Website promotion",
              "Digital sponsorship",
              "Email sponsorship",
              "Virtual class sponsorship",
            ],
            color: "topaz",
          },
        ]}
      />
      <VerticalCaseStudySection caseStudy={caseStudy} />
      <PlatformSection
        topHeading="Grow media sales for your business"
        topCopy="Clever tools that make your on-premise and online activation opportunities more discoverable and easily bookable."
        topGraphics={[
          {
            image: "/images/window@3x.png",
            x: 0,
            y: 0,
            delay: 0.0,
            height: isMobile ? 14.75 : 32.5,
          },
          {
            image: "/images/sales-graph@3x.png",
            x: isMobile ? 1.406 : 3.125,
            y: isMobile ? 4.404 : 9.688,
            delay: 0.2,
            width: isMobile ? 15.938 : 35.063,
          },
          {
            image: "/images/sales-stats@3x.png",
            x: isMobile ? 1.406 : 3.125,
            y: isMobile ? 1.847 : 4.063,
            delay: 0.4,
            width: isMobile ? 15.938 : 35.063,
          },
        ]}
        topButtonLabel="Schedule a demo"
        topButtonHref="/book-a-demo"
        middleHeading="Streamline your bookings"
        middleCopy="Manage and scale your bookings with inventory tools, booking calendars and campaign workflows."
        middleGraphics={[
          {
            image: "/images/window@3x.png",
            x: 0,
            y: 0,
            delay: 0.0,
            height: isMobile ? 14.75 : 32.5,
          },
          {
            image: "/images/activations-logo-health-wellness@3x.png",
            x: isMobile ? 1.42 : 3.125,
            y: isMobile ? 10.417 : 22.917,
            delay: 0.2,
            height: isMobile ? 2.273 : 5.0,
          },
          {
            image: "/images/activations-name@3x.png",
            x: isMobile ? 4.375 : 9.625,
            y: isMobile ? 11.364 : 25.0,
            delay: 0.2,
            height: isMobile ? 0.369 : 0.8125,
          },
          {
            image: "/images/activations-table@3x.png",
            x: isMobile ? 1.932 : 4.25,
            y: isMobile ? 2.178 : 4.792,
            delay: 0.4,
            height: isMobile ? 7.188 : 15.8125,
          },
        ]}
        middleButtonLabel="Schedule a demo"
        middleButtonHref="/book-a-demo"
        bottomHeading="Simplify your payments"
        bottomCopy="Let us handle full vendor management, payments and collections."
        bottomGraphics={[
          {
            image: "/images/window@3x.png",
            x: 0,
            y: 0,
            delay: 0.0,
            height: isMobile ? 14.75 : 32.5,
          },
          {
            image: "/images/payments-summary@3x.png",
            x: isMobile ? 1.136 : 2.5,
            y: isMobile ? 4.318 : 9.5,
            delay: 0.2,
            width: isMobile ? 7.67 : 16.875,
          },
          {
            image: "/images/payments-table@3x.png",
            x: isMobile ? 9.943 : 21.875,
            y: isMobile ? 2.159 : 4.75,
            delay: 0.4,
            width: isMobile ? 7.67 : 16.875,
          },
          {
            image: "/images/payments-button@3x.png",
            x: isMobile ? 1.932 : 4.25,
            y: isMobile ? 2.159 : 4.75,
            delay: 0.6,
            width: isMobile ? 6.051 : 13.313,
          },
        ]}
        bottomButtonLabel="Schedule a demo"
        bottomButtonHref="/book-a-demo"
        lastHeading="Integrate systems"
        lastCopy="Integrate with finance, CRM and partner platforms"
        lastGraphics={[
          {
            image: "/images/window@3x.png",
            x: 0,
            y: 0,
            delay: 0.0,
            height: isMobile ? 14.75 : 32.5,
          },
          {
            image: "/images/integrate@3x.png",
            x: isMobile ? 6.279 : 13.813,
            y: isMobile ? 4.261 : 9.375,
            delay: 0.1,
            width: isMobile ? 6.506 : 14.313,
          },
          {
            image: "/images/integrate-cog-1@3x.png",
            x: isMobile ? 4.689 : 10.25,
            y: isMobile ? 10.739 : 23.625,
            delay: 0.3,
            width: isMobile ? 1.193 : 2.625,
          },
          {
            image: "/images/integrate-cog-1@3x.png",
            x: isMobile ? 12.955 : 28.5,
            y: isMobile ? 2.784 : 6.125,
            delay: 0.4,
            width: isMobile ? 1.193 : 2.625,
          },
          {
            image: "/images/integrate-cog-2@3x.png",
            x: isMobile ? 13.551 : 29.813,
            y: isMobile ? 7.045 : 15.5,
            delay: 0.5,
            width: isMobile ? 1.534 : 3.375,
          },
          {
            image: "/images/integrate-cog-3@3x.png",
            x: isMobile ? 12.585 : 27.688,
            y: isMobile ? 10.654 : 23.438,
            delay: 0.6,
            width: isMobile ? 1.932 : 4.25,
          },
          {
            image: "/images/integrate-cog-4@3x.png",
            x: isMobile ? 3.55 : 7.813,
            y: isMobile ? 2.216 : 4.875,
            delay: 0.7,
            width: isMobile ? 2.443 : 5.375,
          },
        ]}
        lastButtonLabel="Schedule a demo"
        lastButtonHref="/book-a-demo"
        noActionButtons
        signupHref="/book-a-demo"
      />
      <VerticalInfoBanner
        heading="Your own branded platform"
        subHeading="Scale and grow media sales with your own branded media booking platform, integrated to your website."
        checklistItems={[
          "Customizable platform looks & sounds like your brand",
          "Reduce manual data handling, inventory management and invoicing",
          "Simple, low-lift setup",
        ]}
      />
      <VerticalPartnersSection heading="Trusted by the world’s largest multinational brands and agencies" />
      <VerticalNetworkSection
        heading="Join a growing network of Health & Wellness businesses unlocking and growing their media revenue"
        logos={[
          {
            src: "/images/logos/health/XtendBarre_Grey.png",
            alt: "",
            width: isMobile ? "3.75" : "6",
          },
          { src: "/images/logos/health/SLT_Grey.png", alt: "", width: isMobile ? "2.38" : "3.813" },
          { src: "/images/logos/health/KX_Grey.png", alt: "", width: isMobile ? "2.31" : "3.688" },
          {
            src: "/images/logos/health/Coreology_Grey.png",
            alt: "",
            width: isMobile ? "2.73" : "4.375",
          },
          {
            src: "/images/logos/health/ClubCorp_Grey.png",
            alt: "",
            width: isMobile ? "4.58" : "7.329",
          },
          {
            src: "/images/logos/health/SweatCycle_Grey.png",
            alt: "",
            width: isMobile ? "2.31" : "3.688",
          },
          {
            src: "/images/logos/health/BodhiRide_Grey.png",
            alt: "",
            width: isMobile ? "2.58" : "4.125",
          },
          {
            src: "/images/logos/health/ClubLime_Grey.png",
            alt: "",
            width: isMobile ? "5.94" : "9.5",
          },
          {
            src: "/images/logos/health/ListenToYourBody_Grey.png",
            alt: "",
            width: isMobile ? "2.85" : "4.563",
          },
          {
            src: "/images/logos/health/RowHouse_Grey.png",
            alt: "",
            width: isMobile ? "5.94" : "9.5",
          },
          { src: "/images/logos/health/F45_Grey.png", alt: "", width: isMobile ? "2.5" : "4.0" },
        ]}
        height="3.746"
        background-color="charcoal-15"
        autoplay
      />
    </Layout>
  );
};

export default HealthWellnessPage;
